"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionType = exports.validateAction = exports.validateProps = exports.toastSchema = void 0;
var Toast_1 = require("../../actions/Toast");
Object.defineProperty(exports, "ActionType", { enumerable: true, get: function () { return Toast_1.ActionType; } });
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
exports.toastSchema = type_validate_1.matchesObject({
    message: type_validate_1.matchesString(),
    duration: type_validate_1.matchesPositiveInteger(),
    isError: type_validate_1.makeOptional(type_validate_1.matchesBoolean()),
});
function validateProps(props) {
    return type_validate_1.validate(props, exports.toastSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
    switch (action.type) {
        case Toast_1.ActionType.SHOW:
            return type_validate_1.validate(action, utils_1.createActionValidator(Toast_1.ActionType, exports.toastSchema, true));
        default:
            return type_validate_1.validate(action, utils_1.createActionValidator(Toast_1.ActionType));
    }
}
exports.validateAction = validateAction;
