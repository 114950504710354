"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = exports.ActionType = void 0;
var ActionType;
(function (ActionType) {
    ActionType["PUSH"] = "APP::NAVIGATION::HISTORY::PUSH";
    ActionType["REPLACE"] = "APP::NAVIGATION::HISTORY::REPLACE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var Action;
(function (Action) {
    Action["PUSH"] = "PUSH";
    Action["REPLACE"] = "REPLACE";
})(Action = exports.Action || (exports.Action = {}));
