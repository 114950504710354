"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShopifyPing = exports.isShopifyPOS = exports.isShopifyMobile = exports.isMobile = void 0;
function isMobile() {
    return isShopifyMobile() || isShopifyPOS() || isShopifyPing();
}
exports.isMobile = isMobile;
function isShopifyMobile() {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Shopify Mobile') >= 0;
}
exports.isShopifyMobile = isShopifyMobile;
function isShopifyPOS() {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Shopify POS') >= 0;
}
exports.isShopifyPOS = isShopifyPOS;
function isShopifyPing() {
    return typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Shopify Ping') >= 0;
}
exports.isShopifyPing = isShopifyPing;
