"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = exports.ActionType = void 0;
var ActionType;
(function (ActionType) {
    ActionType["FETCH"] = "APP::CART::FETCH";
    ActionType["UPDATE"] = "APP::CART::UPDATE";
    ActionType["SET_CUSTOMER"] = "APP::CART::SET_CUSTOMER";
    ActionType["REMOVE_CUSTOMER"] = "APP::CART::REMOVE_CUSTOMER";
    ActionType["ADD_CUSTOMER_ADDRESS"] = "APP::CART::ADD_CUSTOMER_ADDRESS";
    ActionType["UPDATE_CUSTOMER_ADDRESS"] = "APP::CART::UPDATE_CUSTOMER_ADDRESS";
    ActionType["SET_DISCOUNT"] = "APP::CART::SET_DISCOUNT";
    ActionType["REMOVE_DISCOUNT"] = "APP::CART::REMOVE_DISCOUNT";
    ActionType["SET_PROPERTIES"] = "APP::CART::SET_PROPERTIES";
    ActionType["REMOVE_PROPERTIES"] = "APP::CART::REMOVE_PROPERTIES";
    ActionType["CLEAR"] = "APP::CART::CLEAR";
    ActionType["ADD_LINE_ITEM"] = "APP::CART::ADD_LINE_ITEM";
    ActionType["UPDATE_LINE_ITEM"] = "APP::CART::UPDATE_LINE_ITEM";
    ActionType["REMOVE_LINE_ITEM"] = "APP::CART::REMOVE_LINE_ITEM";
    ActionType["SET_LINE_ITEM_DISCOUNT"] = "APP::CART::SET_LINE_ITEM_DISCOUNT";
    ActionType["REMOVE_LINE_ITEM_DISCOUNT"] = "APP::CART::REMOVE_LINE_ITEM_DISCOUNT";
    ActionType["SET_LINE_ITEM_PROPERTIES"] = "APP::CART::SET_LINE_ITEM_PROPERTIES";
    ActionType["REMOVE_LINE_ITEM_PROPERTIES"] = "APP::CART::REMOVE_LINE_ITEM_PROPERTIES";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var Action;
(function (Action) {
    Action["FETCH"] = "FETCH";
    Action["UPDATE"] = "UPDATE";
    Action["SET_CUSTOMER"] = "SET_CUSTOMER";
    Action["REMOVE_CUSTOMER"] = "REMOVE_CUSTOMER";
    Action["ADD_CUSTOMER_ADDRESS"] = "ADD_CUSTOMER_ADDRESS";
    Action["UPDATE_CUSTOMER_ADDRESS"] = "UPDATE_CUSTOMER_ADDRESS";
    Action["SET_DISCOUNT"] = "SET_DISCOUNT";
    Action["REMOVE_DISCOUNT"] = "REMOVE_DISCOUNT";
    Action["SET_PROPERTIES"] = "SET_PROPERTIES";
    Action["REMOVE_PROPERTIES"] = "REMOVE_PROPERTIES";
    Action["CLEAR"] = "CLEAR";
    Action["ADD_LINE_ITEM"] = "ADD_LINE_ITEM";
    Action["UPDATE_LINE_ITEM"] = "UPDATE_LINE_ITEM";
    Action["REMOVE_LINE_ITEM"] = "REMOVE_LINE_ITEM";
    Action["SET_LINE_ITEM_DISCOUNT"] = "SET_LINE_ITEM_DISCOUNT";
    Action["REMOVE_LINE_ITEM_DISCOUNT"] = "REMOVE_LINE_ITEM_DISCOUNT";
    Action["SET_LINE_ITEM_PROPERTIES"] = "SET_LINE_ITEM_PROPERTIES";
    Action["REMOVE_LINE_ITEM_PROPERTIES"] = "REMOVE_LINE_ITEM_PROPERTIES";
})(Action = exports.Action || (exports.Action = {}));
