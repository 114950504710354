"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Style = exports.Icon = exports.ActionType = exports.Action = void 0;
var Action;
(function (Action) {
    Action["CLICK"] = "CLICK";
    Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
var ActionType;
(function (ActionType) {
    ActionType["CLICK"] = "APP::BUTTON::CLICK";
    ActionType["UPDATE"] = "APP::BUTTON::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var Icon;
(function (Icon) {
    Icon["Print"] = "print";
})(Icon = exports.Icon || (exports.Icon = {}));
var Style;
(function (Style) {
    Style["Danger"] = "danger";
})(Style = exports.Style || (exports.Style = {}));
