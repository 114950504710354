"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComponentType = exports.Group = void 0;
/**
 * Various action groups.
 * @public
 */
var Group;
(function (Group) {
    Group["AuthCode"] = "AuthCode";
    Group["Button"] = "Button";
    Group["ButtonGroup"] = "ButtonGroup";
    Group["Cart"] = "Cart";
    Group["ContextualSaveBar"] = "ContextualSaveBar";
    Group["Error"] = "Error";
    Group["Features"] = "Features";
    Group["FeedbackModal"] = "FeedbackModal";
    Group["Fullscreen"] = "Fullscreen";
    Group["LeaveConfirmation"] = "LeaveConfirmation";
    Group["Link"] = "Link";
    Group["Loading"] = "Loading";
    Group["Menu"] = "Menu";
    Group["Modal"] = "Modal";
    Group["Navigation"] = "Navigation";
    Group["Performance"] = "Performance";
    Group["Pos"] = "Pos";
    Group["Print"] = "Print";
    Group["ResourcePicker"] = "Resource_Picker";
    Group["Scanner"] = "Scanner";
    Group["SessionToken"] = "SessionToken";
    Group["Share"] = "Share";
    Group["TitleBar"] = "TitleBar";
    Group["Toast"] = "Toast";
    Group["MarketingExternalActivityTopBar"] = "MarketingExternalActivityTopBar";
})(Group = exports.Group || (exports.Group = {}));
/**
 * @internal
 */
var ComponentType;
(function (ComponentType) {
    ComponentType["Button"] = "Button";
    ComponentType["ButtonGroup"] = "ButtonGroup";
})(ComponentType = exports.ComponentType || (exports.ComponentType = {}));
