var styles = {
  "Scrollable": "Polaris-Scrollable",
  "horizontal": "Polaris-Scrollable--horizontal",
  "vertical": "Polaris-Scrollable--vertical",
  "verticalHasScrolling": "Polaris-Scrollable--verticalHasScrolling",
  "hasTopShadow": "Polaris-Scrollable--hasTopShadow",
  "hasBottomShadow": "Polaris-Scrollable--hasBottomShadow"
};

export default styles;
