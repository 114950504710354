"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppBridgeContext = void 0;
var react_1 = require("react");
/**
 * App Bridge context.
 *
 * @public
 */
exports.AppBridgeContext = react_1.createContext(null);
