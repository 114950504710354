"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Redirect = exports.isRemotePayload = exports.isAdminSectionPayload = exports.isAdminPathPayload = exports.isAppPayload = exports.toDestination = exports.toApp = exports.toRemote = exports.toAdminSection = exports.toAdminPath = exports.isProductVariantCreateResourcePayload = exports.isProductVariantResourcePayload = exports.isCreateResourcePayload = exports.isResourcePayload = void 0;
var helper_1 = require("../../helper");
var types_1 = require("../../types");
var types_2 = require("./types");
function isResourcePayload(resource) {
    // tslint:disable-next-line:no-boolean-literal-compare
    return typeof resource.id === 'string';
}
exports.isResourcePayload = isResourcePayload;
function isCreateResourcePayload(resource) {
    // tslint:disable-next-line:no-boolean-literal-compare
    return resource.create === true;
}
exports.isCreateResourcePayload = isCreateResourcePayload;
function isProductVariantResourcePayload(resource) {
    var castResource = resource;
    // tslint:disable-next-line:no-boolean-literal-compare
    return castResource.id !== undefined && castResource.variant !== undefined;
}
exports.isProductVariantResourcePayload = isProductVariantResourcePayload;
function isProductVariantCreateResourcePayload(resource) {
    if (!isProductVariantResourcePayload(resource)) {
        return false;
    }
    return isCreateResourcePayload(resource.variant);
}
exports.isProductVariantCreateResourcePayload = isProductVariantCreateResourcePayload;
function toAdminPath(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: types_2.ActionType.ADMIN_PATH,
    });
}
exports.toAdminPath = toAdminPath;
function toAdminSection(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: types_2.ActionType.ADMIN_SECTION,
    });
}
exports.toAdminSection = toAdminSection;
function toRemote(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: types_2.ActionType.REMOTE,
    });
}
exports.toRemote = toRemote;
function toApp(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Navigation,
        type: types_2.ActionType.APP,
    });
}
exports.toApp = toApp;
function toDestination(action, payload, id) {
    switch (action) {
        case types_2.Action.APP:
            var appPayload = isAppPayload(payload) ? payload : { path: payload };
            return toApp(__assign({ id: id }, appPayload));
        case types_2.Action.ADMIN_PATH:
            var adminPathPayload = isAdminPathPayload(payload) ? payload : { path: payload };
            return toAdminPath(__assign({ id: id }, adminPathPayload));
        case types_2.Action.ADMIN_SECTION:
            var adminSectionPayload = isAdminSectionPayload(payload)
                ? payload
                : { section: payload };
            return toAdminSection(__assign({ id: id }, adminSectionPayload));
        case types_2.Action.REMOTE:
            var remotePayload = isRemotePayload(payload) ? payload : { url: payload };
            return toRemote(__assign({ id: id }, remotePayload));
    }
}
exports.toDestination = toDestination;
function isAppPayload(payload) {
    return typeof payload === 'object' && payload.hasOwnProperty('path');
}
exports.isAppPayload = isAppPayload;
function isAdminPathPayload(payload) {
    return typeof payload === 'object' && payload.hasOwnProperty('path');
}
exports.isAdminPathPayload = isAdminPathPayload;
function isAdminSectionPayload(payload) {
    return (typeof payload === 'object' &&
        typeof payload.section === 'object' &&
        payload.section.hasOwnProperty('name'));
}
exports.isAdminSectionPayload = isAdminSectionPayload;
function isRemotePayload(payload) {
    return typeof payload === 'object' && payload.hasOwnProperty('url');
}
exports.isRemotePayload = isRemotePayload;
var Redirect = /** @class */ (function (_super) {
    __extends(Redirect, _super);
    function Redirect(app) {
        return _super.call(this, app, 'Redirect', types_1.Group.Navigation) || this;
    }
    Object.defineProperty(Redirect.prototype, "payload", {
        get: function () {
            return { id: this.id };
        },
        enumerable: false,
        configurable: true
    });
    Redirect.prototype.dispatch = function (action, payload) {
        var redirectAction = toDestination(action, payload, this.payload.id);
        this.app.dispatch(redirectAction);
        return this;
    };
    return Redirect;
}(helper_1.ActionSet));
exports.Redirect = Redirect;
function create(app) {
    return new Redirect(app);
}
exports.create = create;
