var styles = {
  "Select": "Polaris-Select",
  "disabled": "Polaris-Select--disabled",
  "Content": "Polaris-Select__Content",
  "InlineLabel": "Polaris-Select__InlineLabel",
  "Icon": "Polaris-Select__Icon",
  "Backdrop": "Polaris-Select__Backdrop",
  "placeholder": "Polaris-Select--placeholder",
  "error": "Polaris-Select--error",
  "Input": "Polaris-Select__Input",
  "SelectedOption": "Polaris-Select__SelectedOption",
  "Prefix": "Polaris-Select__Prefix",
  "hover": "Polaris-Select--hover"
};

export default styles;
