"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useAppBridge_1 = require("../../useAppBridge");
var router_1 = require("./router");
/**
 * useRoutePropagation
 *
 * @remarks
 * A hook which keeps the Shopify admin url in sync with the app url
 *
 * @public
 */
function useClientRouting(history) {
    var app = useAppBridge_1.useAppBridge();
    react_1.useEffect(function () {
        return router_1.handleRouteChange(app, history);
    }, [app, history]);
}
exports.default = useClientRouting;
