"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Toast = exports.clear = exports.show = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
function show(toastMessage) {
    return helper_1.actionWrapper({
        group: types_1.Group.Toast,
        payload: toastMessage,
        type: types_2.ActionType.SHOW,
    });
}
exports.show = show;
function clear(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Toast,
        type: types_2.ActionType.CLEAR,
    });
}
exports.clear = clear;
var Toast = /** @class */ (function (_super) {
    __extends(Toast, _super);
    function Toast(app, options) {
        var _this = _super.call(this, app, types_1.Group.Toast, types_1.Group.Toast) || this;
        _this.message = '';
        _this.duration = 5000;
        _this.set(options);
        return _this;
    }
    Object.defineProperty(Toast.prototype, "options", {
        get: function () {
            return {
                duration: this.duration,
                isError: this.isError,
                message: this.message,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Toast.prototype, "payload", {
        get: function () {
            return __assign({ id: this.id }, this.options);
        },
        enumerable: false,
        configurable: true
    });
    Toast.prototype.set = function (options) {
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var message = mergedOptions.message, duration = mergedOptions.duration, isError = mergedOptions.isError;
        this.message = message;
        this.duration = duration;
        this.isError = isError;
        return this;
    };
    Toast.prototype.dispatch = function (action) {
        switch (action) {
            case types_2.Action.SHOW:
                var openAction = show(this.payload);
                this.app.dispatch(openAction);
                break;
            case types_2.Action.CLEAR:
                this.app.dispatch(clear({ id: this.id }));
                break;
        }
        return this;
    };
    return Toast;
}(helper_1.ActionSet));
exports.Toast = Toast;
function create(app, options) {
    return new Toast(app, options);
}
exports.create = create;
