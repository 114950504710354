"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionType = exports.Action = void 0;
var Action;
(function (Action) {
    Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "APP::BUTTONGROUP::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
