var styles = {
  "Button": "Polaris-Button",
  "pressed": "Polaris-Button--pressed",
  "disabled": "Polaris-Button--disabled",
  "connectedDisclosure": "Polaris-Button--connectedDisclosure",
  "Content": "Polaris-Button__Content",
  "plain": "Polaris-Button--plain",
  "textAlignLeft": "Polaris-Button--textAlignLeft",
  "textAlignCenter": "Polaris-Button--textAlignCenter",
  "textAlignRight": "Polaris-Button--textAlignRight",
  "Icon": "Polaris-Button__Icon",
  "fullWidth": "Polaris-Button--fullWidth",
  "hidden": "Polaris-Button--hidden",
  "Spinner": "Polaris-Button__Spinner",
  "primary": "Polaris-Button--primary",
  "destructive": "Polaris-Button--destructive",
  "outline": "Polaris-Button--outline",
  "loading": "Polaris-Button--loading",
  "removeUnderline": "Polaris-Button--removeUnderline",
  "iconOnly": "Polaris-Button--iconOnly",
  "sizeSlim": "Polaris-Button--sizeSlim",
  "sizeLarge": "Polaris-Button--sizeLarge",
  "monochrome": "Polaris-Button--monochrome",
  "Text": "Polaris-Button__Text",
  "ConnectedDisclosureWrapper": "Polaris-Button__ConnectedDisclosureWrapper",
  "ConnectedDisclosure": "Polaris-Button__ConnectedDisclosure"
};

export default styles;
