"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateHistory = void 0;
var app_bridge_1 = require("@shopify/app-bridge");
var actions_1 = require("@shopify/app-bridge/actions");
var globals_1 = require("./globals");
// These parameters are added to the iframe url but we don't want to propagate
// them up to the address bar as they are not provided by the application
// Removing hmac is especially important as its presence may cause infinite
// oauth authentication loops
var embeddedFrameParamsToRemove = ['hmac', 'locale', 'protocol', 'session', 'shop', 'timestamp'];
function updateHistory(app, location) {
    return __awaiter(this, void 0, void 0, function () {
        var selfWindow, topWindow, renderedInTheTopWindow, renderedAsMainApp, normalizedLocation, pathname, search, hash, locationStr;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    selfWindow = globals_1.getSelfWindow();
                    topWindow = globals_1.getTopWindow();
                    renderedInTheTopWindow = selfWindow === topWindow;
                    return [4 /*yield*/, app.getState('context').then(function (context) {
                            return context === app_bridge_1.Context.Main;
                        })];
                case 1:
                    renderedAsMainApp = _a.sent();
                    if (renderedInTheTopWindow || !renderedAsMainApp) {
                        return [2 /*return*/];
                    }
                    normalizedLocation = getNormalizedURL(location);
                    embeddedFrameParamsToRemove.forEach(function (param) { return normalizedLocation.searchParams.delete(param); });
                    pathname = normalizedLocation.pathname, search = normalizedLocation.search, hash = normalizedLocation.hash;
                    locationStr = "" + pathname + search + hash;
                    actions_1.History.create(app).dispatch(actions_1.History.Action.REPLACE, locationStr);
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateHistory = updateHistory;
function getNormalizedURL(location) {
    var origin = globals_1.getOrigin();
    if (typeof location === 'string') {
        return new URL(location, origin);
    }
    var pathname = location.pathname, search = location.search, hash = location.hash;
    return new URL("" + pathname + search + hash, origin);
}
