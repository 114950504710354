"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Features = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
/**
 * A set of Actions for Updating, Requesting Features of AppBridge
 * @public
 */
var Features = /** @class */ (function (_super) {
    __extends(Features, _super);
    function Features(app, options) {
        return _super.call(this, app, types_1.Group.Features, types_1.Group.Features, options ? options.id : undefined) || this;
    }
    /**
     * @public
     */
    Features.prototype.dispatch = function (action, payload) {
        switch (action) {
            case types_2.Action.REQUEST:
                this.dispatchFeaturesAction(types_2.ActionType.REQUEST, payload);
                break;
        }
        return this;
    };
    /**
     * @internal
     */
    Features.prototype.dispatchFeaturesAction = function (type, payload) {
        this.app.dispatch(helper_1.actionWrapper({
            group: types_1.Group.Features,
            type: type,
            payload: __assign(__assign({}, (payload || {})), { id: this.id }),
        }));
    };
    return Features;
}(helper_1.ActionSet));
exports.Features = Features;
/**
 * @public
 */
function create(app, options) {
    return new Features(app, options);
}
exports.create = create;
