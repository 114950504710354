var styles = {
  "ContextualSaveBar": "Polaris-Frame-ContextualSaveBar",
  "LogoContainer": "Polaris-Frame-ContextualSaveBar__LogoContainer",
  "ContextControl": "Polaris-Frame-ContextualSaveBar__ContextControl",
  "Message": "Polaris-Frame-ContextualSaveBar__Message",
  "Contents": "Polaris-Frame-ContextualSaveBar__Contents",
  "fullWidth": "Polaris-Frame-ContextualSaveBar--fullWidth",
  "ActionContainer": "Polaris-Frame-ContextualSaveBar__ActionContainer",
  "Action": "Polaris-Frame-ContextualSaveBar__Action"
};

export default styles;
