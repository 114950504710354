"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Size = exports.ActionType = exports.Action = void 0;
var Action;
(function (Action) {
    Action["OPEN"] = "OPEN";
    Action["CLOSE"] = "CLOSE";
    Action["UPDATE"] = "UPDATE";
    Action["UPDATE_SIZE"] = "UPDATE_SIZE";
    Action["UPDATE_CONTENT"] = "CONTENT::UPDATE";
    Action["DATA"] = "DATA";
})(Action = exports.Action || (exports.Action = {}));
/**
 * Action types for the Modal group
 * @public
 */
var ActionType;
(function (ActionType) {
    ActionType["OPEN"] = "APP::MODAL::OPEN";
    ActionType["CLOSE"] = "APP::MODAL::CLOSE";
    ActionType["UPDATE"] = "APP::MODAL::UPDATE";
    ActionType["UPDATE_CONTENT"] = "APP::MODAL::CONTENT::UPDATE";
    ActionType["FOOTER_BUTTON_CLICK"] = "APP::MODAL::FOOTER::BUTTON::CLICK";
    ActionType["FOOTER_BUTTON_UPDATE"] = "APP::MODAL::FOOTER::BUTTON::UPDATE";
    ActionType["UPDATE_SIZE"] = "APP::MODAL::UPDATE_SIZE";
    ActionType["DATA"] = "APP::MODAL::DATA";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
/**
 * Options available to the Modal `size` param
 * @public
 */
var Size;
(function (Size) {
    /** Small modal size */
    Size["Small"] = "small";
    /** Medium modal size */
    Size["Medium"] = "medium";
    /** Large modal size (wider than medium) */
    Size["Large"] = "large";
    /** @deprecated as of 1.6.5 */
    Size["Full"] = "full";
    /**
     * @deprecated as of 1.12.x
     * @remarks
     * This option has been removed in favour of the `setUpModalAutoSizing` utility.
     * See `app-bridge-utils` package for more information
     */
    Size["Auto"] = "auto";
})(Size = exports.Size || (exports.Size = {}));
