"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFrameless = exports.isDevelopmentClient = exports.isProduction = exports.isDevelopment = exports.isClient = exports.isServer = void 0;
exports.isServer = typeof window === 'undefined';
exports.isClient = !exports.isServer;
exports.isDevelopment = typeof process !== 'undefined' && process.env && process.env.NODE_ENV === 'development';
exports.isProduction = !exports.isDevelopment;
exports.isDevelopmentClient = exports.isDevelopment && exports.isClient;
exports.isFrameless = exports.isClient && ((_b = (_a = window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.indexOf(' Frameless ')) > 0;
