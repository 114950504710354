"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.app = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
function app() {
    return helper_1.actionWrapper({
        group: types_1.Group.Print,
        type: types_2.ActionType.APP,
    });
}
exports.app = app;
