"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.ModalIframe = exports.ModalMessage = exports.Modal = exports.isMessageModal = exports.isIframeModal = exports.data = exports.update = exports.clickFooterButton = exports.updateModalSize = exports.closeModal = exports.openModal = void 0;
var buttonHelper_1 = require("../buttonHelper");
var helper_1 = require("../helper");
var types_1 = require("../types");
var Button_1 = require("../Button");
var types_2 = require("./types");
var FOOTER_BUTTON_PROPS = {
    group: types_1.Group.Modal,
    subgroups: ['Footer'],
    type: types_1.ComponentType.Button,
};
function openModal(modalPayload) {
    return helper_1.actionWrapper({
        group: types_1.Group.Modal,
        payload: modalPayload,
        type: types_2.ActionType.OPEN,
    });
}
exports.openModal = openModal;
function closeModal(modalClosePayload) {
    return helper_1.actionWrapper({
        group: types_1.Group.Modal,
        payload: modalClosePayload,
        type: types_2.ActionType.CLOSE,
    });
}
exports.closeModal = closeModal;
/**
 * Action creator for modal update size action
 * @internal
 */
function updateModalSize(updateSizePayload) {
    return helper_1.actionWrapper({
        group: types_1.Group.Modal,
        payload: updateSizePayload,
        type: types_2.ActionType.UPDATE_SIZE,
    });
}
exports.updateModalSize = updateModalSize;
function clickFooterButton(id, payload) {
    var component = __assign({ id: id }, FOOTER_BUTTON_PROPS);
    return Button_1.clickButton(types_1.Group.Modal, component, payload);
}
exports.clickFooterButton = clickFooterButton;
function update(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Modal,
        type: types_2.ActionType.UPDATE,
    });
}
exports.update = update;
function data(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.Modal,
        type: types_2.ActionType.DATA,
    });
}
exports.data = data;
function isIframeModal(options) {
    return (typeof options.url === 'string' ||
        typeof options.path === 'string');
}
exports.isIframeModal = isIframeModal;
function isMessageModal(options) {
    return typeof options.message === 'string';
}
exports.isMessageModal = isMessageModal;
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.size = types_2.Size.Small;
        return _this;
    }
    Object.defineProperty(Modal.prototype, "footer", {
        get: function () {
            if (!this.footerPrimary && !this.footerSecondary) {
                return undefined;
            }
            return {
                buttons: {
                    primary: this.footerPrimary,
                    secondary: this.footerSecondary,
                },
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Modal.prototype, "footerOptions", {
        get: function () {
            if (!this.footerPrimaryOptions && !this.footerSecondaryOptions) {
                return undefined;
            }
            return {
                buttons: {
                    primary: this.footerPrimaryOptions,
                    secondary: this.footerSecondaryOptions,
                },
            };
        },
        enumerable: false,
        configurable: true
    });
    Modal.prototype.close = function () {
        this.app.dispatch(closeModal({ id: this.id }));
    };
    Modal.prototype.setFooterPrimaryButton = function (newOptions, updateCb) {
        var _this = this;
        var subgroups = FOOTER_BUTTON_PROPS.subgroups;
        this.footerPrimaryOptions = this.getChildButton(newOptions, this.footerPrimaryOptions);
        this.footerPrimary = this.footerPrimaryOptions
            ? buttonHelper_1.getSingleButton(this, this.footerPrimaryOptions, subgroups, function (newPayload) {
                _this.updatePrimaryFooterButton(newPayload, updateCb);
            })
            : undefined;
    };
    Modal.prototype.setFooterSecondaryButtons = function (newOptions, updateCb) {
        var _this = this;
        var subgroups = FOOTER_BUTTON_PROPS.subgroups;
        var newButtons = newOptions || [];
        var currentOptions = (this.footerOptions && this.footerOptions.buttons.secondary) || [];
        this.footerSecondaryOptions = this.getUpdatedChildActions(newButtons, currentOptions);
        this.footerSecondary = this.footerSecondaryOptions
            ? this.footerSecondaryOptions.map(function (action) {
                return buttonHelper_1.getSingleButton(_this, action, subgroups, function (newPayload) {
                    _this.updateSecondaryFooterButton(newPayload, updateCb);
                });
            })
            : undefined;
    };
    Modal.prototype.getChildButton = function (newAction, currentAction) {
        var newButtons = newAction ? [newAction] : [];
        var currentButtons = currentAction ? [currentAction] : [];
        var updatedButton = this.getUpdatedChildActions(newButtons, currentButtons);
        return updatedButton ? updatedButton[0] : undefined;
    };
    Modal.prototype.updatePrimaryFooterButton = function (newPayload, updateCb) {
        if (!this.footer || !this.footer.buttons.primary) {
            return;
        }
        if (helper_1.updateActionFromPayload(this.footer.buttons.primary, newPayload)) {
            updateCb();
        }
    };
    Modal.prototype.updateSecondaryFooterButton = function (newPayload, updateCb) {
        if (!this.footer || !this.footer.buttons || !this.footer.buttons.secondary) {
            return;
        }
        var updated;
        for (var _i = 0, _a = this.footer.buttons.secondary; _i < _a.length; _i++) {
            var action = _a[_i];
            updated = helper_1.updateActionFromPayload(action, newPayload);
            if (updated) {
                break;
            }
        }
        if (updated) {
            updateCb();
        }
    };
    return Modal;
}(helper_1.ActionSetWithChildren));
exports.Modal = Modal;
var ModalMessage = /** @class */ (function (_super) {
    __extends(ModalMessage, _super);
    function ModalMessage(app, options) {
        var _this = _super.call(this, app, types_1.Group.Modal, types_1.Group.Modal) || this;
        _this.set(options, false);
        return _this;
    }
    Object.defineProperty(ModalMessage.prototype, "payload", {
        get: function () {
            return __assign(__assign({}, this.options), { footer: this.footer, id: this.id });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ModalMessage.prototype, "options", {
        get: function () {
            return {
                footer: this.footerOptions,
                message: this.message,
                size: this.size,
                title: this.title,
            };
        },
        enumerable: false,
        configurable: true
    });
    ModalMessage.prototype.set = function (options, shouldUpdate) {
        var _this = this;
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var title = mergedOptions.title, footer = mergedOptions.footer, message = mergedOptions.message, size = mergedOptions.size;
        this.title = title;
        this.message = message;
        this.size = size;
        this.setFooterPrimaryButton(footer ? footer.buttons.primary : undefined, function () {
            _this.dispatch(types_2.Action.UPDATE);
        });
        this.setFooterSecondaryButtons(footer ? footer.buttons.secondary : undefined, function () {
            _this.dispatch(types_2.Action.UPDATE);
        });
        if (shouldUpdate) {
            this.dispatch(types_2.Action.UPDATE);
        }
        return this;
    };
    ModalMessage.prototype.dispatch = function (action) {
        switch (action) {
            case types_2.Action.OPEN:
                this.app.dispatch(openModal(this.payload));
                break;
            case types_2.Action.CLOSE:
                this.close();
                break;
            case types_2.Action.UPDATE:
                this.app.dispatch(update(this.payload));
                break;
        }
        return this;
    };
    return ModalMessage;
}(Modal));
exports.ModalMessage = ModalMessage;
var ModalIframe = /** @class */ (function (_super) {
    __extends(ModalIframe, _super);
    function ModalIframe(app, options) {
        var _this = _super.call(this, app, types_1.Group.Modal, types_1.Group.Modal) || this;
        _this.set(options, false);
        return _this;
    }
    Object.defineProperty(ModalIframe.prototype, "payload", {
        get: function () {
            return __assign(__assign({}, this.options), { footer: this.footer, id: this.id });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ModalIframe.prototype, "options", {
        get: function () {
            return {
                footer: this.footerOptions,
                path: this.path,
                size: this.size,
                title: this.title,
                url: this.url,
                loading: this.loading,
            };
        },
        enumerable: false,
        configurable: true
    });
    ModalIframe.prototype.set = function (options, shouldUpdate) {
        var _this = this;
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var title = mergedOptions.title, footer = mergedOptions.footer, path = mergedOptions.path, url = mergedOptions.url, size = mergedOptions.size, loading = mergedOptions.loading;
        this.title = title;
        this.url = url;
        this.path = path;
        this.size = size;
        this.loading = loading;
        this.setFooterPrimaryButton(footer ? footer.buttons.primary : undefined, function () {
            _this.dispatch(types_2.Action.UPDATE);
        });
        this.setFooterSecondaryButtons(footer ? footer.buttons.secondary : undefined, function () {
            _this.dispatch(types_2.Action.UPDATE);
        });
        if (shouldUpdate) {
            this.dispatch(types_2.Action.UPDATE);
        }
        return this;
    };
    ModalIframe.prototype.dispatch = function (action, payload) {
        switch (action) {
            case types_2.Action.OPEN:
                this.app.dispatch(openModal(this.payload));
                break;
            case types_2.Action.CLOSE:
                this.close();
                break;
            case types_2.Action.UPDATE:
                this.app.dispatch(update(this.payload));
                break;
            case types_2.Action.DATA:
                this.app.dispatch(data(payload || {}));
                break;
        }
        return this;
    };
    return ModalIframe;
}(Modal));
exports.ModalIframe = ModalIframe;
exports.create = function (app, options) {
    if (isIframeModal(options)) {
        return new ModalIframe(app, options);
    }
    return new ModalMessage(app, options);
};
