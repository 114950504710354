import { useState, useEffect, createElement, Suspense } from 'react';
export { lazy } from 'react';

function ClientSuspense (props) {
  var state = useState(false);
  var isClient = state[0];
  var setClient = state[1];

  useEffect(function () {
    setClient(true);
  }, [setClient]);

  return isClient ? createElement(Suspense, props) : props.fallback
}

export { ClientSuspense as Suspense };
