"use strict";
/**
 * Action Types for the Features group
 * @public
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = exports.ActionType = void 0;
var ActionType;
(function (ActionType) {
    ActionType["OPEN_CAMERA"] = "APP::SCANNER::OPEN::CAMERA";
    ActionType["CAPTURE"] = "APP::SCANNER::CAPTURE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
/**
 * Actions available to the Features group
 * @public
 */
var Action;
(function (Action) {
    Action["OPEN_CAMERA"] = "OPEN::CAMERA";
    Action["CAPTURE"] = "CAPTURE";
})(Action = exports.Action || (exports.Action = {}));
