"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.ContextualSaveBar = exports.discard = exports.save = exports.hide = exports.show = exports.ActionType = exports.Action = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
/**
 * ContextualSaveBar action enum
 */
var Action;
(function (Action) {
    Action["DISCARD"] = "DISCARD";
    Action["SAVE"] = "SAVE";
    Action["SHOW"] = "SHOW";
    Action["HIDE"] = "HIDE";
    Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
/**
 * ContextualSaveBar action type enum
 * @remarks includes the action prefix and group
 *
 */
var ActionType;
(function (ActionType) {
    ActionType["DISCARD"] = "APP::CONTEXTUAL_SAVE_BAR::DISCARD";
    ActionType["SAVE"] = "APP::CONTEXTUAL_SAVE_BAR::SAVE";
    ActionType["SHOW"] = "APP::CONTEXTUAL_SAVE_BAR::SHOW";
    ActionType["HIDE"] = "APP::CONTEXTUAL_SAVE_BAR::HIDE";
    ActionType["UPDATE"] = "APP::CONTEXTUAL_SAVE_BAR::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
function createContextBarAction(action, payload) {
    return helper_1.actionWrapper({
        group: types_1.Group.ContextualSaveBar,
        type: ActionType[action],
        payload: payload,
    });
}
function show(payload) {
    return createContextBarAction(Action.SHOW, payload);
}
exports.show = show;
function hide(payload) {
    return createContextBarAction(Action.HIDE, payload);
}
exports.hide = hide;
function save(payload) {
    return createContextBarAction(Action.SAVE, payload);
}
exports.save = save;
function discard(payload) {
    return createContextBarAction(Action.DISCARD, payload);
}
exports.discard = discard;
/**
 * ContextualSaveBar action set
 */
var ContextualSaveBar = /** @class */ (function (_super) {
    __extends(ContextualSaveBar, _super);
    /**
     * Returns a new instance of a ContextualSaveBar action set
     * @param app the client application
     */
    function ContextualSaveBar(app, options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, app, types_1.Group.ContextualSaveBar, types_1.Group.ContextualSaveBar) || this;
        _this.options = options;
        _this.set(options, false);
        return _this;
    }
    Object.defineProperty(ContextualSaveBar.prototype, "payload", {
        /**
         * Returns the action set payload
         */
        get: function () {
            return __assign({ id: this.id }, this.options);
        },
        enumerable: false,
        configurable: true
    });
    ContextualSaveBar.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        this.options = mergedOptions;
        if (shouldUpdate) {
            this.dispatch(Action.UPDATE);
        }
        return this;
    };
    /**
     * Dispatches a given action with the action set payload
     * @param action the action enum
     * @returns the action set instance
     */
    ContextualSaveBar.prototype.dispatch = function (action) {
        this.app.dispatch(createContextBarAction(action, this.payload));
        return this;
    };
    return ContextualSaveBar;
}(helper_1.ActionSet));
exports.ContextualSaveBar = ContextualSaveBar;
/**
 * Returns a new instance of a ContextualSaveBar action set
 * @param app the client application
 *
 */
function create(app, options) {
    return new ContextualSaveBar(app, options);
}
exports.create = create;
