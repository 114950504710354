import { isObject as isObject$1 } from './is-object.js';

function pluckDeep(obj, key) {
  if (!obj) {
    return null;
  }

  const keys = Object.keys(obj);

  for (const currKey of keys) {
    if (currKey === key) {
      return obj[key];
    }

    if (isObject$1(obj[currKey])) {
      const plucked = pluckDeep(obj[currKey], key);

      if (plucked) {
        return plucked;
      }
    }
  }

  return null;
}

export { pluckDeep };
