"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionType = exports.validateAction = void 0;
var Print_1 = require("../../actions/Print");
Object.defineProperty(exports, "ActionType", { enumerable: true, get: function () { return Print_1.ActionType; } });
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
function validateAction(action) {
    return type_validate_1.validate(action, utils_1.createActionValidator(Print_1.ActionType));
}
exports.validateAction = validateAction;
