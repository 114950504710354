"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformActions = exports.generateRedirect = void 0;
var actions_1 = require("@shopify/app-bridge/actions");
function generateRedirect(appBridge, url, target, external) {
    if (target === void 0) { target = 'APP'; }
    if (url == null) {
        return undefined;
    }
    var redirect = actions_1.Redirect.create(appBridge);
    var payload = external === true
        ? {
            url: url,
            newContext: true,
        }
        : url;
    return function () {
        redirect.dispatch(redirectAction(target, external), payload);
    };
}
exports.generateRedirect = generateRedirect;
function redirectAction(target, external) {
    if (external === true) {
        return actions_1.Redirect.Action.REMOTE;
    }
    return actions_1.Redirect.Action[target];
}
function transformActions(appBridge, _a) {
    var primaryAction = _a.primaryAction, secondaryActions = _a.secondaryActions, actionGroups = _a.actionGroups;
    var primary = transformPrimaryAction(appBridge, primaryAction);
    var secondary = __spreadArrays(transformSecondaryActions(appBridge, secondaryActions), transformActionGroups(appBridge, actionGroups));
    return {
        primary: primary,
        secondary: secondary,
    };
}
exports.transformActions = transformActions;
function transformAction(appBridge, action) {
    var style = action.destructive === true ? actions_1.Button.Style.Danger : undefined;
    var button = actions_1.Button.create(appBridge, {
        label: action.content || '',
        disabled: action.disabled,
        loading: action.loading,
        style: style,
    });
    if (action.onAction) {
        button.subscribe(actions_1.Button.Action.CLICK, action.onAction);
    }
    var redirect = generateRedirect(appBridge, action.url, action.target, action.external);
    if (redirect != null) {
        button.subscribe(actions_1.Button.Action.CLICK, redirect);
    }
    return button;
}
function transformPrimaryAction(appBridge, primaryAction) {
    if (primaryAction == null) {
        return undefined;
    }
    var primary = transformAction(appBridge, primaryAction);
    return primary;
}
function transformSecondaryActions(appBridge, secondaryActions) {
    if (secondaryActions === void 0) { secondaryActions = []; }
    var secondary = __spreadArrays(secondaryActions.map(function (secondaryAction) {
        return transformAction(appBridge, secondaryAction);
    }));
    return secondary;
}
function transformActionGroups(appBridge, actionGroups) {
    if (actionGroups === void 0) { actionGroups = []; }
    var buttonGroups = __spreadArrays(actionGroups.map(function (group) {
        var buttons = group.actions.map(function (groupAction) {
            return transformAction(appBridge, groupAction);
        });
        return actions_1.ButtonGroup.create(appBridge, { label: group.title, buttons: buttons });
    }));
    return buttonGroups;
}
