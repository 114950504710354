"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.MarketingExternalActivityTopBar = exports.update = exports.clickActionButton = exports.MarketingActivityStatusBadgeType = exports.ActionType = exports.Action = void 0;
var Button_1 = require("../Button");
var buttonHelper_1 = require("../buttonHelper");
var helper_1 = require("../helper");
var types_1 = require("../types");
var Action;
(function (Action) {
    Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "APP::MARKETING_EXTERNAL_ACTIVITY_TOP_BAR::UPDATE";
    ActionType["BUTTON_CLICK"] = "APP::MARKETING_EXTERNAL_ACTIVITY_TOP_BAR::BUTTONS::BUTTON::CLICK";
    ActionType["BUTTON_UPDATE"] = "APP::MARKETING_EXTERNAL_ACTIVITY_TOP_BAR::BUTTONS::BUTTON::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var MarketingActivityStatusBadgeType;
(function (MarketingActivityStatusBadgeType) {
    MarketingActivityStatusBadgeType["Default"] = "DEFAULT";
    MarketingActivityStatusBadgeType["Success"] = "SUCCESS";
    MarketingActivityStatusBadgeType["Attention"] = "ATTENTION";
    MarketingActivityStatusBadgeType["Warning"] = "WARNING";
    MarketingActivityStatusBadgeType["Info"] = "INFO";
})(MarketingActivityStatusBadgeType = exports.MarketingActivityStatusBadgeType || (exports.MarketingActivityStatusBadgeType = {}));
var MARKETING_ACTIVITY_TOPBAR_BUTTON_PROPS = {
    group: types_1.Group.MarketingExternalActivityTopBar,
    subgroups: ['Buttons'],
};
function clickActionButton(id, payload) {
    var type = types_1.ComponentType.Button;
    var component = __assign({ id: id, type: type }, MARKETING_ACTIVITY_TOPBAR_BUTTON_PROPS);
    return Button_1.clickButton(types_1.Group.MarketingExternalActivityTopBar, component, payload);
}
exports.clickActionButton = clickActionButton;
function update(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.MarketingExternalActivityTopBar,
        type: ActionType.UPDATE,
    });
}
exports.update = update;
var MarketingExternalActivityTopBar = /** @class */ (function (_super) {
    __extends(MarketingExternalActivityTopBar, _super);
    function MarketingExternalActivityTopBar(app, options) {
        var _this = _super.call(this, app, types_1.Group.MarketingExternalActivityTopBar, types_1.Group.MarketingExternalActivityTopBar) || this;
        // Trigger 'update' on creation
        _this.set(options);
        return _this;
    }
    Object.defineProperty(MarketingExternalActivityTopBar.prototype, "buttons", {
        get: function () {
            if (!this.primary && !this.secondary) {
                return undefined;
            }
            return {
                primary: this.primary,
                secondary: this.secondary,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MarketingExternalActivityTopBar.prototype, "buttonsOptions", {
        get: function () {
            if (!this.primaryOptions && !this.secondaryOptions) {
                return undefined;
            }
            return {
                primary: this.primaryOptions,
                secondary: this.secondaryOptions,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MarketingExternalActivityTopBar.prototype, "options", {
        get: function () {
            return {
                title: this.title,
                status: this.status,
                saving: this.saving,
                saved: this.saved,
                buttons: this.buttonsOptions,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MarketingExternalActivityTopBar.prototype, "payload", {
        get: function () {
            return __assign(__assign({}, this.options), { buttons: this.buttons, id: this.id });
        },
        enumerable: false,
        configurable: true
    });
    MarketingExternalActivityTopBar.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var mergedOptions = helper_1.getMergedProps(this.options, options);
        var title = mergedOptions.title, buttons = mergedOptions.buttons, saved = mergedOptions.saved, saving = mergedOptions.saving, status = mergedOptions.status;
        this.title = title;
        this.saving = saving;
        this.saved = saved;
        this.status = status;
        this.setPrimaryButton(buttons ? buttons.primary : undefined);
        this.setSecondaryButtons(buttons ? buttons.secondary : undefined);
        if (shouldUpdate) {
            this.dispatch(Action.UPDATE);
        }
        return this;
    };
    MarketingExternalActivityTopBar.prototype.dispatch = function (action) {
        switch (action) {
            case Action.UPDATE:
                this.app.dispatch(update(this.payload));
                break;
        }
        return this;
    };
    MarketingExternalActivityTopBar.prototype.getButton = function (button, subgroups, updateCb) {
        return buttonHelper_1.getSingleButton(this, button, subgroups, updateCb);
    };
    MarketingExternalActivityTopBar.prototype.updatePrimaryButton = function (newPayload) {
        if (!this.primary) {
            return;
        }
        if (helper_1.updateActionFromPayload(this.primary, newPayload)) {
            this.dispatch(Action.UPDATE);
        }
    };
    MarketingExternalActivityTopBar.prototype.updateSecondaryButtons = function (newPayload) {
        if (!this.secondary) {
            return;
        }
        var buttonToUpdate = this.secondary.find(function (action) { return action.id === newPayload.id; });
        if (!buttonToUpdate) {
            return;
        }
        var updated = helper_1.updateActionFromPayload(buttonToUpdate, newPayload);
        if (updated) {
            this.dispatch(Action.UPDATE);
        }
    };
    MarketingExternalActivityTopBar.prototype.setPrimaryButton = function (newOptions) {
        this.primaryOptions = this.getChildButton(newOptions, this.primaryOptions);
        this.primary = this.primaryOptions
            ? this.getButton(this.primaryOptions, MARKETING_ACTIVITY_TOPBAR_BUTTON_PROPS.subgroups, this.updatePrimaryButton)
            : undefined;
    };
    MarketingExternalActivityTopBar.prototype.setSecondaryButtons = function (newOptions) {
        var _this = this;
        var newButtons = newOptions || [];
        var currentButtons = this.secondaryOptions || [];
        this.secondaryOptions = this.getUpdatedChildActions(newButtons, currentButtons);
        this.secondary = this.secondaryOptions
            ? this.secondaryOptions.map(function (action) {
                return _this.getButton(action, MARKETING_ACTIVITY_TOPBAR_BUTTON_PROPS.subgroups, _this.updateSecondaryButtons);
            })
            : undefined;
    };
    MarketingExternalActivityTopBar.prototype.updateSaving = function (saving) {
        this.saving = saving;
        this.dispatch(Action.UPDATE);
    };
    MarketingExternalActivityTopBar.prototype.updateSaved = function (saved) {
        this.saved = saved;
        this.dispatch(Action.UPDATE);
    };
    MarketingExternalActivityTopBar.prototype.updateStatus = function (newPayload) {
        this.status = newPayload;
        this.dispatch(Action.UPDATE);
    };
    MarketingExternalActivityTopBar.prototype.getChildButton = function (newAction, currentAction) {
        var newButtons = newAction ? [newAction] : [];
        var currentButtons = currentAction ? [currentAction] : [];
        var updatedButton = this.getUpdatedChildActions(newButtons, currentButtons);
        return updatedButton ? updatedButton[0] : undefined;
    };
    return MarketingExternalActivityTopBar;
}(helper_1.ActionSetWithChildren));
exports.MarketingExternalActivityTopBar = MarketingExternalActivityTopBar;
function create(app, options) {
    return new MarketingExternalActivityTopBar(app, options);
}
exports.create = create;
