"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionType = exports.validateAction = exports.validateProps = exports.linkActionSchema = exports.linkPropsSchema = void 0;
var actions_1 = require("../../actions");
var type_validate_1 = require("../type-validate");
var utils_1 = require("../utils");
var AppLink_1 = require("../../actions/Link/AppLink");
Object.defineProperty(exports, "ActionType", { enumerable: true, get: function () { return AppLink_1.ActionType; } });
var allowedRedirectTypes;
(function (allowedRedirectTypes) {
    allowedRedirectTypes["APP"] = "APP";
})(allowedRedirectTypes || (allowedRedirectTypes = {}));
exports.linkPropsSchema = type_validate_1.matchesObject({
    label: type_validate_1.matchesString(),
    destination: utils_1.relativeUrlSchema,
    redirectType: type_validate_1.matchesEnum(allowedRedirectTypes),
});
exports.linkActionSchema = type_validate_1.matchesObject({
    label: type_validate_1.matchesString(),
    destination: utils_1.relativePathSchema,
    redirectType: type_validate_1.matchesEnum(allowedRedirectTypes),
});
function validateProps(props) {
    return type_validate_1.validate(props, exports.linkPropsSchema);
}
exports.validateProps = validateProps;
function validateAction(action) {
    var actionType = actions_1.AppLink.ActionType;
    var schema;
    switch (action.type) {
        case actions_1.AppLink.ActionType.UPDATE:
            actionType = actions_1.AppLink.ActionType;
            schema = exports.linkActionSchema;
    }
    return type_validate_1.validate(action, utils_1.createActionValidator(actionType, schema, true, true));
}
exports.validateAction = validateAction;
