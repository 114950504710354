"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.AppLink = exports.update = exports.ActionType = exports.Action = void 0;
var helper_1 = require("../../helper");
var types_1 = require("../../types");
var Redirect_1 = require("../../Navigation/Redirect");
var Action;
(function (Action) {
    Action["UPDATE"] = "UPDATE";
})(Action = exports.Action || (exports.Action = {}));
var ActionType;
(function (ActionType) {
    ActionType["UPDATE"] = "APP::LINK::UPDATE";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
function update(updatePayload) {
    return helper_1.actionWrapper({
        group: types_1.Group.Link,
        type: ActionType.UPDATE,
        payload: updatePayload,
    });
}
exports.update = update;
var AppLink = /** @class */ (function (_super) {
    __extends(AppLink, _super);
    function AppLink(app, options) {
        var _this = _super.call(this, app, types_1.Group.Link, types_1.Group.Link) || this;
        _this.label = '';
        _this.destination = '';
        _this.set(options, false);
        return _this;
    }
    Object.defineProperty(AppLink.prototype, "options", {
        get: function () {
            var _a = this, label = _a.label, destination = _a.destination;
            return {
                label: label,
                destination: destination,
                redirectType: Redirect_1.Action.APP,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppLink.prototype, "payload", {
        get: function () {
            var _a = this.options, label = _a.label, destination = _a.destination, redirectType = _a.redirectType;
            var path = destination;
            return {
                id: this.id,
                label: label,
                destination: { path: path },
                redirectType: redirectType,
            };
        },
        enumerable: false,
        configurable: true
    });
    AppLink.prototype.set = function (options, shouldUpdate) {
        if (shouldUpdate === void 0) { shouldUpdate = true; }
        var _a = helper_1.getMergedProps(this.options, options), label = _a.label, destination = _a.destination;
        this.label = label;
        this.destination = destination;
        if (shouldUpdate) {
            this.dispatch(ActionType.UPDATE);
        }
        return this;
    };
    AppLink.prototype.dispatch = function (action) {
        switch (action) {
            case ActionType.UPDATE:
                this.app.dispatch(update(this.payload));
                break;
        }
        return this;
    };
    return AppLink;
}(helper_1.ActionSet));
exports.AppLink = AppLink;
function create(app, options) {
    return new AppLink(app, options);
}
exports.create = create;
