"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.respond = exports.request = exports.Action = exports.ActionType = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var ActionType;
(function (ActionType) {
    ActionType["REQUEST"] = "APP::AUTH_CODE::REQUEST";
    ActionType["RESPOND"] = "APP::AUTH_CODE::RESPOND";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var Action;
(function (Action) {
    Action["REQUEST"] = "REQUEST";
    Action["RESPOND"] = "RESPOND";
})(Action = exports.Action || (exports.Action = {}));
function request(id) {
    return helper_1.actionWrapper({
        group: types_1.Group.AuthCode,
        type: ActionType.REQUEST,
        payload: { id: id },
    });
}
exports.request = request;
function respond(payload) {
    return helper_1.actionWrapper({
        payload: payload,
        group: types_1.Group.AuthCode,
        type: ActionType.RESPOND,
    });
}
exports.respond = respond;
