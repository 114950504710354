var styles = {
  "Card": "Polaris-Card",
  "subdued": "Polaris-Card--subdued",
  "Section-hideOnPrint": "Polaris-Card__Section--hideOnPrint",
  "hideOnPrint": "Polaris-Card--hideOnPrint",
  "Header": "Polaris-Card__Header",
  "Section": "Polaris-Card__Section",
  "Section-fullWidth": "Polaris-Card__Section--fullWidth",
  "Section-flush": "Polaris-Card__Section--flush",
  "Section-subdued": "Polaris-Card__Section--subdued",
  "SectionHeader": "Polaris-Card__SectionHeader",
  "Subsection": "Polaris-Card__Subsection",
  "Footer": "Polaris-Card__Footer",
  "LeftJustified": "Polaris-Card__LeftJustified"
};

export default styles;
