"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create = exports.Cart = exports.setLineItemProperties = exports.removeLineItemDiscount = exports.setLineItemDiscount = exports.removeLineItem = exports.updateLineItem = exports.addLineItem = exports.removeProperties = exports.setProperties = exports.setDiscount = exports.updateCustomerAddress = exports.addCustomerAddress = exports.setCustomer = exports.update = exports.fetch = void 0;
var helper_1 = require("../helper");
var types_1 = require("../types");
var types_2 = require("./types");
/**
 * Cart action
 * @internal
 */
function createCartAction(type, payload) {
    if (payload === void 0) { payload = {}; }
    return helper_1.actionWrapper({
        group: types_1.Group.Cart,
        type: type,
        payload: payload,
    });
}
function fetch() {
    return createCartAction(types_2.ActionType.FETCH);
}
exports.fetch = fetch;
function update(payload) {
    return createCartAction(types_2.ActionType.UPDATE, payload);
}
exports.update = update;
function setCustomer(payload) {
    return createCartAction(types_2.ActionType.SET_CUSTOMER, payload);
}
exports.setCustomer = setCustomer;
function addCustomerAddress(payload) {
    return createCartAction(types_2.ActionType.ADD_CUSTOMER_ADDRESS, payload);
}
exports.addCustomerAddress = addCustomerAddress;
function updateCustomerAddress(payload) {
    return createCartAction(types_2.ActionType.UPDATE_CUSTOMER_ADDRESS, payload);
}
exports.updateCustomerAddress = updateCustomerAddress;
function setDiscount(payload) {
    return createCartAction(types_2.ActionType.SET_DISCOUNT, payload);
}
exports.setDiscount = setDiscount;
function setProperties(payload) {
    return createCartAction(types_2.ActionType.SET_PROPERTIES, payload);
}
exports.setProperties = setProperties;
function removeProperties(payload) {
    return createCartAction(types_2.ActionType.REMOVE_PROPERTIES, payload);
}
exports.removeProperties = removeProperties;
function addLineItem(payload) {
    return createCartAction(types_2.ActionType.ADD_LINE_ITEM, payload);
}
exports.addLineItem = addLineItem;
function updateLineItem(payload) {
    return createCartAction(types_2.ActionType.UPDATE_LINE_ITEM, payload);
}
exports.updateLineItem = updateLineItem;
function removeLineItem(payload) {
    return createCartAction(types_2.ActionType.REMOVE_LINE_ITEM, payload);
}
exports.removeLineItem = removeLineItem;
function setLineItemDiscount(payload) {
    return createCartAction(types_2.ActionType.SET_LINE_ITEM_DISCOUNT, payload);
}
exports.setLineItemDiscount = setLineItemDiscount;
function removeLineItemDiscount(payload) {
    return createCartAction(types_2.ActionType.REMOVE_LINE_ITEM_DISCOUNT, payload);
}
exports.removeLineItemDiscount = removeLineItemDiscount;
function setLineItemProperties(payload) {
    return createCartAction(types_2.ActionType.SET_LINE_ITEM_PROPERTIES, payload);
}
exports.setLineItemProperties = setLineItemProperties;
/**
 * Cart
 */
var Cart = /** @class */ (function (_super) {
    __extends(Cart, _super);
    function Cart(app, options) {
        return _super.call(this, app, types_1.Group.Cart, types_1.Group.Cart, options ? options.id : undefined) || this;
    }
    Cart.prototype.dispatch = function (action, payload) {
        switch (action) {
            case types_2.Action.FETCH:
                this.dispatchCartAction(types_2.ActionType.FETCH);
                break;
            case types_2.Action.UPDATE:
                this.dispatchCartAction(types_2.ActionType.UPDATE, payload);
                break;
            case types_2.Action.SET_CUSTOMER:
                this.dispatchCartAction(types_2.ActionType.SET_CUSTOMER, payload);
                break;
            case types_2.Action.REMOVE_CUSTOMER:
                this.dispatchCartAction(types_2.ActionType.REMOVE_CUSTOMER, payload);
                break;
            case types_2.Action.ADD_CUSTOMER_ADDRESS:
                this.dispatchCartAction(types_2.ActionType.ADD_CUSTOMER_ADDRESS, payload);
                break;
            case types_2.Action.UPDATE_CUSTOMER_ADDRESS:
                this.dispatchCartAction(types_2.ActionType.UPDATE_CUSTOMER_ADDRESS, payload);
                break;
            case types_2.Action.SET_DISCOUNT:
                this.dispatchCartAction(types_2.ActionType.SET_DISCOUNT, payload);
                break;
            case types_2.Action.REMOVE_DISCOUNT:
                this.dispatchCartAction(types_2.ActionType.REMOVE_DISCOUNT, payload);
                break;
            case types_2.Action.SET_PROPERTIES:
                this.dispatchCartAction(types_2.ActionType.SET_PROPERTIES, payload);
                break;
            case types_2.Action.REMOVE_PROPERTIES:
                this.dispatchCartAction(types_2.ActionType.REMOVE_PROPERTIES, payload);
                break;
            case types_2.Action.CLEAR:
                this.dispatchCartAction(types_2.ActionType.CLEAR, payload);
                break;
            case types_2.Action.ADD_LINE_ITEM:
                this.dispatchCartAction(types_2.ActionType.ADD_LINE_ITEM, payload);
                break;
            case types_2.Action.UPDATE_LINE_ITEM:
                this.dispatchCartAction(types_2.ActionType.UPDATE_LINE_ITEM, payload);
                break;
            case types_2.Action.REMOVE_LINE_ITEM:
                this.dispatchCartAction(types_2.ActionType.REMOVE_LINE_ITEM, payload);
                break;
            case types_2.Action.SET_LINE_ITEM_DISCOUNT:
                this.dispatchCartAction(types_2.ActionType.SET_LINE_ITEM_DISCOUNT, payload);
                break;
            case types_2.Action.REMOVE_LINE_ITEM_DISCOUNT:
                this.dispatchCartAction(types_2.ActionType.REMOVE_LINE_ITEM_DISCOUNT, payload);
                break;
            case types_2.Action.SET_LINE_ITEM_PROPERTIES:
                this.dispatchCartAction(types_2.ActionType.SET_LINE_ITEM_PROPERTIES, payload);
                break;
            case types_2.Action.REMOVE_LINE_ITEM_PROPERTIES:
                this.dispatchCartAction(types_2.ActionType.REMOVE_LINE_ITEM_PROPERTIES, payload);
                break;
        }
        return this;
    };
    Cart.prototype.dispatchCartAction = function (type, payload) {
        this.app.dispatch(createCartAction(type, __assign(__assign({}, payload), { id: this.id })));
    };
    return Cart;
}(helper_1.ActionSet));
exports.Cart = Cart;
function create(app, options) {
    return new Cart(app, options);
}
exports.create = create;
