"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useAppBridge_1 = require("../../useAppBridge");
var route_propagator_1 = require("./route-propagator");
/**
 * useRoutePropagation
 *
 * @remarks
 * A hook which keeps the Shopify admin url in sync with the app url
 *
 * @public
 */
function useRoutePropagation(location) {
    var app = useAppBridge_1.useAppBridge();
    react_1.useEffect(function () {
        route_propagator_1.updateHistory(app, location);
    }, [app, location]);
}
exports.default = useRoutePropagation;
