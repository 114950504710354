"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceType = exports.ActionType = exports.Action = void 0;
var Action;
(function (Action) {
    Action["ADMIN_PATH"] = "ADMIN::PATH";
    Action["ADMIN_SECTION"] = "ADMIN::SECTION";
    Action["REMOTE"] = "REMOTE";
    Action["APP"] = "APP";
})(Action = exports.Action || (exports.Action = {}));
var ActionType;
(function (ActionType) {
    ActionType["ADMIN_SECTION"] = "APP::NAVIGATION::REDIRECT::ADMIN::SECTION";
    ActionType["ADMIN_PATH"] = "APP::NAVIGATION::REDIRECT::ADMIN::PATH";
    ActionType["REMOTE"] = "APP::NAVIGATION::REDIRECT::REMOTE";
    ActionType["APP"] = "APP::NAVIGATION::REDIRECT::APP";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
var ResourceType;
(function (ResourceType) {
    ResourceType["Product"] = "products";
    ResourceType["Collection"] = "collections";
    ResourceType["Order"] = "orders";
    ResourceType["Customer"] = "customers";
    ResourceType["Discount"] = "discounts";
})(ResourceType = exports.ResourceType || (exports.ResourceType = {}));
