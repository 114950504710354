"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrigin = exports.getTopWindow = exports.getSelfWindow = void 0;
function getSelfWindow() {
    return window.self;
}
exports.getSelfWindow = getSelfWindow;
function getTopWindow() {
    return window.top;
}
exports.getTopWindow = getTopWindow;
function getOrigin() {
    return location.origin;
}
exports.getOrigin = getOrigin;
