"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppActionType = exports.ActionType = exports.Action = void 0;
var Action;
(function (Action) {
    Action["INVALID_ACTION"] = "INVALID_ACTION";
    Action["INVALID_ACTION_TYPE"] = "INVALID_ACTION_TYPE";
    Action["INVALID_OPTIONS"] = "INVALID_OPTIONS";
    Action["INVALID_PAYLOAD"] = "INVALID_PAYLOAD";
    Action["UNEXPECTED_ACTION"] = "UNEXPECTED_ACTION";
    Action["PERSISTENCE"] = "PERSISTENCE";
    Action["UNSUPPORTED_OPERATION"] = "UNSUPPORTED_OPERATION";
    Action["NETWORK"] = "NETWORK";
    Action["PERMISSION"] = "PERMISSION";
})(Action = exports.Action || (exports.Action = {}));
// Errors triggered in response to an action
var ActionType;
(function (ActionType) {
    ActionType["INVALID_ACTION"] = "APP::ERROR::INVALID_ACTION";
    ActionType["INVALID_ACTION_TYPE"] = "APP::ERROR::INVALID_ACTION_TYPE";
    ActionType["INVALID_PAYLOAD"] = "APP::ERROR::INVALID_PAYLOAD";
    ActionType["INVALID_OPTIONS"] = "APP::ERROR::INVALID_OPTIONS";
    ActionType["UNEXPECTED_ACTION"] = "APP::ERROR::UNEXPECTED_ACTION";
    ActionType["PERSISTENCE"] = "APP::ERROR::PERSISTENCE";
    ActionType["UNSUPPORTED_OPERATION"] = "APP::ERROR::UNSUPPORTED_OPERATION";
    ActionType["NETWORK"] = "APP::ERROR::NETWORK";
    ActionType["PERMISSION"] = "APP::ERROR::PERMISSION";
    ActionType["FAILED_AUTHENTICATION"] = "APP::ERROR::FAILED_AUTHENTICATION";
    ActionType["INVALID_ORIGIN"] = "APP::ERROR::INVALID_ORIGIN";
})(ActionType = exports.ActionType || (exports.ActionType = {}));
// Errors thrown in response to app setup
var AppActionType;
(function (AppActionType) {
    AppActionType["INVALID_CONFIG"] = "APP::ERROR::INVALID_CONFIG";
    AppActionType["MISSING_CONFIG"] = "APP::APP_ERROR::MISSING_CONFIG";
    AppActionType["MISSING_APP_BRIDGE_MIDDLEWARE"] = "APP::APP_ERROR::MISSING_APP_BRIDGE_MIDDLEWARE";
    AppActionType["WINDOW_UNDEFINED"] = "APP::APP_ERROR::WINDOW_UNDEFINED";
    AppActionType["MISSING_LOCAL_ORIGIN"] = "APP::APP_ERROR::MISSING_LOCAL_ORIGIN";
    AppActionType["MISSING_HOST_PROVIDER"] = "APP::APP_ERROR::MISSING_HOST_PROVIDER";
    AppActionType["MISSING_ROUTER_CONTEXT"] = "APP::APP_ERROR::MISSING_ROUTER_CONTEXT";
    AppActionType["MISSING_HISTORY_BLOCK"] = "APP::APP_ERROR::MISSING_HISTORY_BLOCK";
})(AppActionType = exports.AppActionType || (exports.AppActionType = {}));
